//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

var str = validate.stringifyValue;
validate.stringifyValue = function (value) {
    return str(value).split('_')[0];
};

/**
 * View base class for all other views.
 */
export default
class ViewBase extends React.Component {

    componentDidMount() {
        if (this.props.onComponentMounted) {
            this.props.onComponentMounted(this);
        }
    }

    static merge(obj1, obj2) {
        var result = {}, prop;
        for (prop in obj1) {
            if (obj1.hasOwnProperty(prop) && prop !== 'children') {
                result[prop] = obj1[prop];
            }
        }
        for (prop in obj2) {
            if (obj2.hasOwnProperty(prop) && prop !== 'children') {
                result[prop] = obj2[prop];
            }
        }
        return result;
    }

    registerChild(inst) {

        var children = this.state.children || [];
        children.push(inst);
        this.setState({ children: children });
    }

    getChildrenState() {

        var result = {};

        // iterate over the child objects...
        (this.state.children || []).forEach(i => {

            // merge in the child's state object.
            result = ViewBase.merge(result, i.state);

            // if the child has children, then recursively merge their state as well.
            if (i.getChildrenState) {
                result = ViewBase.merge(result, i.getChildrenState());
            }
        });

        return result;
    }

    /**
     * Validates the children form contents.
     * @returns {{}} An empty object if the form is valid, otherwise an object containing error messages.
     */
    validateChildrenState() {

        var result = {};

        for (let i = 0; i < (this.state.children || '').length; i++) {

            let child = this.state.children[i];
            if (child.validateState) {
                result = ViewBase.merge(result, child.validateState());
            }
        }

        return result;
    }

    renderBreadcrumbHome() {
        return <div>
            <a href="/organization/dashboard" id="btnHome" className="left btn btn-lg btn-primary btn-breadcrumb"><i className="fa fa-home"></i>&nbsp;Home</a>
            <h2 className="left">&nbsp;<i className="fa fa-chevron-left"></i>&nbsp;</h2>
            </div>
    }

    renderChildren() {
        return React.Children.map(this.props.children,
                i => React.cloneElement(i, { onComponentMounted: this.registerChild.bind(this) }));
    }

    renderValidation(name) {
        if (this.state.errors && this.state.errors[name]) {
            return (<div className="validation-message">
                <i className="fa fa-times-circle fa-lg"></i>
                <span>&nbsp;{this.state.errors[name]}</span>
            </div>);
        }
    }

    static renderOptions(data) {
        return data.map(opt => (<option value={opt.id}>{opt.name}</option>));
    }

    validateState() {
        var errors = validate(this.state, this.constraints);
        this.setState({ isValid: !errors, errors });
        return errors || {};
    }

    static getValue(e) {
        if (e.tagName === 'INPUT' && e.type === 'checkbox') {
            return e.checked;
        }
        else if (e.tagName === 'INPUT' && e.type === 'radio') {
            var radios = document.getElementsByName(e.getAttribute("name"));

            for(var i = 0; i < radios.length; i++) {
                if(radios[i].checked) {
                    return radios[i].value;
                }
            }
        }
        else {
            return e.value;
        }
    }

    handleChange(event) {
        var state = {};
        state[event.target.getAttribute('name')] = ViewBase.getValue(event.target);
        this.setState(state);
    }
}