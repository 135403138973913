//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import request from 'superagent';
import config from '../config';
import { getToken, getSession } from './session';

const DEFAULT_PASSWORD = 'N0taPa$$w0RDN0taPa$$w0RDN0taPa$$w0RD';

function toServerModel (model, isAdmin) {

    // you can't update anything else if you're applying the verification code.
    if (model.emailVerificationCode && !isAdmin) {
        return {
            email_verification_code: model.emailVerificationCode
        };
    }

    var result = {};
    if (model.emailAddress && model.emailAddress === model.confirmEmailAddress) {
        result.email_address = model.emailAddress;
    }

    if (model.password && model.password === model.confirmPassword && model.password !== DEFAULT_PASSWORD) {
        result.updated_password = model.password;
    }

    if (model.name) {
        result.name = model.name;
    }

    if (model.verifyPassword) {
        result.passwordText = model.verifyPassword;
    }

    if (isAdmin) {
        if (model.isActive !== undefined) {
            result.is_active = model.isActive;
        }
    }

    return result;
}

export function getUser() {
    return new Promise((resolve, reject) => {
        request
            .get(`${config.api.url}/users/current`)
            .set('Accept', 'application/json')
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

/**
 *
 * @param model
 * @param id
 * @param isAdmin Indicates whether client-side validation should be ignored because of an administrative action. Server-side will re-validate all requests.
 */
export function updateUser(model, id, isAdmin = false) {
    return new Promise((resolve, reject) => {
        request
            .patch(`${config.api.url}/users/${id || 'current'}`)
            .send(toServerModel(model, isAdmin))
            .set('Accept', 'application/json')
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function updateUsertemp(model, id, isAdmin = false) {
    return new Promise((resolve, reject) => {
        request
            .put(`${config.api.url}/users/${id || 'current'}`)
            .send(toServerModel(model, isAdmin))
            .set('Accept', 'application/json')
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function clearDatabase() {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/users/clear-database`)
            .send()
            .set('Accept', 'application/json')
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function initiateUserReset(model) {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/users/reset-account`)
            .send(model)
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function validateUserReset(model) {
    return new Promise((resolve, reject) => {
        console.log('validateUserReset: ' + JSON.stringify(model));
        request
            .put(`${config.api.url}/users/reset-account`)
            .send(model)
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function getHomePage() {
    var data = getSession();
    if (data.type === 'C') {
        return '/consumer/dashboard';
    }
    else if (data.type === 'T') {
        return '/organization/dashboard';
    }
    else if (data.type === 'A') {
        return '/admin/dashboard';
    }
    else {
        return '/';
    }
}

export function checkEmail(email) {
    return new Promise((resolve, reject) => {
        request
            .get(`${config.api.url}/users/check-email`)
            .query({ email })
            .send()
            .end((e, r) => e ? reject(e) : resolve(r.body.result === 'ok'));
    });
}