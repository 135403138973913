//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react'

import { LoginDisplay } from '../../views/components';
import { isAuthenticated, logError } from '../../services/session';
import pageEvents from '../../events/page';

React.render(
    React.createElement(LoginDisplay, null),
    document.getElementById('loginDisplay')
);

function updateMainMenu() {
    if (isAuthenticated()) {
        $('#menuItemRegister').hide();
        $('#menuItemMyAccount').show();
    }
    else {
        $('#menuItemRegister').show();
        $('#menuItemMyAccount').hide();
    }
}

updateMainMenu();

pageEvents.on('user:signIn', updateMainMenu);
pageEvents.on('user:signOut', updateMainMenu);

window.onerror = function(errorMessage, errorUrl, errorLine) {
    logError(errorMessage, {
        url: errorUrl,
        lineNumber: errorLine
    });
};
