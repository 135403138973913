//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import request from 'superagent';
import { initiateSession } from './session';
import config from '../config';
import { clean, translateTimezone, untranslateTimezone } from './utils';
import { getToken, setSession } from './session';
import _ from 'lodash';

function toServerModel (model) {

    var key = null;

    // process timezones
    if (model.setTimezone) {
        model.timezone = translateTimezone(model.timezone, model.useArizonaTime);
    }

    // add the categories
    if (model.setCategories) {

        model.categories = [];
        model.subcategories = [];
        model.additional = {};

        // get the categories...
        for (key in model) {
            if (model.hasOwnProperty(key) && key.indexOf('category_') === 0 && model[key]) {
                model.categories.push(key.substring('category_'.length));
            }
        }

        // add the subcategories...
        for (key in model) {
            if (model.hasOwnProperty(key) && key.indexOf('subcategory_') === 0 && model[key]) {
                model.categories.push(key.substr('subcategory_'.length, 1));
                model.subcategories.push(key.substring('subcategory_'.length));
            }
        }

        model.categories = _(model.categories).uniq().value();
    }

    // process the availability records:
    if (model.setAvailability) {

        var weekdayMapping = {
            'timeOfWeekMonday': 1,
            'timeOfWeekTuesday': 2,
            'timeOfWeekWednesday': 3,
            'timeOfWeekThursday': 4,
            'timeOfWeekFriday': 5,
            'timeOfWeekSaturday': 6,
            'timeOfWeekSunday': 7
        };

        var timeMapping = {
            'timeOfDay8to10AM': {start: '08:00', end: '10:00'},
            'timeOfDay10to12AM': {start: '10:00', end: '12:00'},
            'timeOfDay12to2PM': {start: '12:00', end: '14:00'},
            'timeOfDay2to4PM': {start: '14:00', end: '16:00'},
            'timeOfDay4to6PM': {start: '16:00', end: '18:00'},
            'timeOfDay6to8PM': {start: '18:00', end: '20:00'},
            'timeOfDay8to10PM': {start: '20:00', end: '22:00'}
        };

        model.availability = [];
        var times = [], days = [];
        for (key in model) {
            if (model.hasOwnProperty(key)) {
                if (weekdayMapping[key] && model[key]) {
                    days.push(weekdayMapping[key]);
                }
                else if (timeMapping[key] && model[key]) {
                    times.push(timeMapping[key]);
                }
            }
        }

        days.forEach(day => {
            times.forEach(time => {
                model.availability.push({
                    weekday: day,
                    start: time.start,
                    end: time.end
                });
            });
        });
    }

    // final cleaning
    return clean(model);
}

function toClientModel (model) {

    var i = 0;

    // process timezones
    var data = untranslateTimezone(model.timezone);
    model.timezone = data.timezone;
    model.useArizonaTime = data.useArizonaTime;

    // process categories
    if (model.categories) {
        for (i = 0; i < model.categories.length; i++) {
            model['category_' + model.categories[i]] = true;
        }
    }

    // process subcategories
    if (model.subcategories) {
        for (i = 0; i < model.subcategories.length; i++) {
            model['subcategory_' + model.subcategories[i]] = true;
        }
    }

    // process the availability records:
    var weekdayMapping = {
        1: 'timeOfWeekMonday',
        2: 'timeOfWeekTuesday',
        3: 'timeOfWeekWednesday',
        4: 'timeOfWeekThursday',
        5: 'timeOfWeekFriday',
        6: 'timeOfWeekSaturday',
        7: 'timeOfWeekSunday'
    };

    var timeMapping = {
        '08:00': 'timeOfDay8to10AM',
        '10:00': 'timeOfDay10to12AM',
        '12:00': 'timeOfDay12to2PM',
        '14:00': 'timeOfDay2to4PM',
        '16:00': 'timeOfDay4to6PM',
        '18:00': 'timeOfDay6to8PM',
        '20:00': 'timeOfDay8to10PM'
    };

    model.availability.forEach(available => {
        model[weekdayMapping[available.weekday]] = true;
        model[timeMapping[available.start]] = true;
    });

    return model;
}

/**
 * Given a model object, calls the OptInCall API to create a new consumer account.
 * @param model The data to use when creating the account.
 * @returns {Promise.<T>}
 */
export function createConsumer(model) {

    model.setTimezone = true;
    model.setCategories = true;
    model.setAvailability = true;

    function createUser() {
        return new Promise((resolve, reject) =>
            request
                .post(`${config.api.url}/users`)
                .send({
                    emailAddress: model.emailAddress,
                    passwordText: model.password,
                    name: model.firstName + ' ' + model.lastName,
                    userTypeId: 'C',
                    externalCategory: model.externalCategory,
                    externalCustomerId: model.externalCustomerId,
                    externalCampaign: model.externalCampaign,
                    externalMedium: model.externalMedium,
                    externalSource: model.externalSource
                })
                .end((e, r) => e ? reject(e) : resolve(r.body)));
    }

    function authenticate() {
        return initiateSession(model.emailAddress, model.password)
            .then(setSession);
    }

    function createConsumer() {
        return new Promise((resolve, reject) =>
            request
                .post(`${config.api.url}/consumers`)
                .send(toServerModel(model))
                .set('x-auth-token', getToken())
                .end((e, r) => e ? reject(e) : resolve(r.body)));
    }

    return createUser()
        .then(() => authenticate())
        .then(() => createConsumer())
}

export function removeConsumer() {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/consumers/current/removal`)
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function updateConsumer(model) {
    return new Promise((resolve, reject) => {
        request
            .patch(`${config.api.url}/consumers/current`)
            .send(toServerModel(model))
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function deactivateConsumer() {
    return new Promise((resolve, reject) => {
        request
            .patch(`${config.api.url}/consumers/current`)
            .send({ isActive: false })
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function activateConsumer() {
    return new Promise((resolve, reject) => {
        request
            .patch(`${config.api.url}/consumers/current`)
            .send({ isActive: true })
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function getConsumer() {
    return new Promise((resolve, reject) => {
        request
            .get(`${config.api.url}/consumers/current`)
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(toClientModel(r.body)));
    });
}

export function getConsumerStatement(month, year) {

    var nextMonth = month < 12 ? month + 1 : 1,
        nextYear = month < 12 ? year : year + 1;

    var params = { start: `${month}/2/${year}`, end: `${nextMonth}/1/${nextYear}` };

    return new Promise((resolve, reject) => {
       request
            .get(`${config.api.url}/consumers/current/statement`)
            .set('x-auth-token', getToken())
            .query(params)
            .end((e, r) => e ? reject(e) : resolve(r.text));
    });
}

export function requestPhoneVerification() {
    return new Promise((resolve, reject) => {
       request
           .post(`${config.api.url}/consumers/current/phone-verification`)
           .send()
           .set('x-auth-token', getToken())
           .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}


export function verifyPhone(code) {
    return new Promise((resolve, reject) => {
        request
            .put(`${config.api.url}/consumers/current/phone-verification/current`)
            .send({ code })
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function resend() {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/consumers/current/resend`)
            .send()
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function getReward(id) {
    return new Promise((resolve, reject) => {
        request
            .get(`${config.api.url}/rewards/${id}`)
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}

export function redeemReward(model) {
    return new Promise((resolve, reject) => {
       request
           .post(`${config.api.url}/consumers/current/reward`)
           .send(model)
           .set('x-auth-token', getToken())
           .end((e, r) => {
               if(e){
                   reject(e);
               }else{
                   resolve(r.body);
               }
               
           });
    });
}

export function checkPhone(phone) {
    return new Promise((resolve, reject) => {
        request
            .get(`${config.api.url}/consumers/check-phone`)
            .query({ phone })
            .send()
            .end((e, r) => e ? reject(e) : resolve(r.body.result === 'ok'));
    });
}

export function sendContactMessage (userType, message) {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/messaging/contactMessages`)
            .send({ userType, message })
            .end((e, r) => e ? reject(e) : resolve(r.body.result === 'ok'));
    });
}