//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import request from 'superagent';
import config from '../config';
import pageEvents from '../events/page';

const TOKEN_KEY = 'optincall_session_token';
const SESSION_KEY = 'optincall_session_data';

/**
 * Attempts to initiate a session with the given email/password combination.
 * @param email The email address.
 * @param password The password.
 * @returns {Promise}
 */
export function initiateSession(email, password) {
    return new Promise((resolve, reject) =>
        request
            .post(`${config.api.url}/sessions`)
            .send({
                email_address: email,
                passwordText: password
            })
            .set('Accept', 'application/json')
            .end((e, r) => e ? reject(e) : resolve(r.body)));
}

export function getToken() {
    return window.localStorage.getItem(TOKEN_KEY) ||
           window.sessionStorage.getItem(TOKEN_KEY) || null;
}

export function isAuthenticated() {

    var session = getSession();

    return (!!session && session.isAuthenticated);
}

export function getSession() {
    var result = window.localStorage.getItem(SESSION_KEY) ||
                 window.sessionStorage.getItem(SESSION_KEY) || 'null';
    result = JSON.parse(result);
    if (result) {
        result.isRemember = window.localStorage.getItem(SESSION_KEY) !== undefined;
    }
    return result;
}

export function setSession(session, rememberMe = false) {

    var sessionData = {
        isAuthenticated: false
    };

    if (session) {

        var storage = (rememberMe ? window.localStorage : window.sessionStorage);

        sessionData = {
            id: session.userId,
            emailAddress: session.userEmail,
            name: session.userName,
            type: session.userType,
            isAuthenticated: true
        };

        storage.setItem(TOKEN_KEY, session.token);
        storage.setItem(SESSION_KEY, JSON.stringify(sessionData));
    }
    else {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(SESSION_KEY);
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.removeItem(SESSION_KEY);
    }
    pageEvents.emit(session ? 'user:signIn' : 'user:signOut', sessionData);
}

export function logError(message, data) {
    return new Promise((resolve, reject) => {
        request
            .post(`${config.api.url}/sessions/logging`)
            .send({
                level: 'error',
                message: message,
                data: data
            })
            .set('Accept', 'application/json')
            .set('x-auth-token', getToken())
            .end((e, r) => e ? reject(e) : resolve(r.body));
    });
}