//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';

/**
 * Represents a wizard control with previous/next buttons.
 */
export default
class Wizard extends ViewBase {

    /**
     * Default constructor.
     */
    constructor() {

        super();

        this.state = {
            step: 1,
            message: null,
            children: [],
            isFinished: false
        };
    }

    /**
     * Called after component loads.
     */
    componentDidMount() {

        super.componentDidMount();

        //console.log('Register setMessage event');
        //events.on(`${this.props.channel}:setMessage`, msg => this.setState({ message: msg }));

        setTimeout(() => this.changeStep(1), 0);

        React.findDOMNode(this)
            .addEventListener('keydown', e => {
                if (e.keyCode === 13 && e.target.classList.contains('submit-wizard')) {
                    this.onNextStep();
                }
            });
    }

    /**
     * Counts the number of steps in the wizard.
     * @returns {*}
     */
    getStepCount() {
        return React.Children.count(this.props.children);
    }

    /**
     * Changes the displayed wizard step.
     * @param step The step number to change to.
     * @returns {boolean}
     */
    changeStep(step) {

        var count = this.getStepCount();

        // not a valid step number.
        if (step < 1) {
            return false;
        }
        else {

            // only validate the current step if the user trying to move forward.
            if (step > this.state.step) {

                var errors = this.state.children[this.state.step - 1].validateChildrenState();
                if (Object.keys(errors).length > 0) {
                    return false;
                }
            }

            // set the children states accordingly.
            for (var i = 0; i < (this.state.children || '').length; i++) {
                if (i == step - 1) {
                    this.state.children[i].show();
                }
                else {
                    this.state.children[i].hide();
                }
            }

            // if the wizard is finished, call the event handler.
            if (count < step) {
                this.doFinish();
            }
            else {
                var message = this.state.children[step - 1].getMessage();

                this.setState({
                    step: step,
                    message: message
                });
            }
        }
    }

    doFinish() {

        this.setState({ isFinished: true }, () => {
            if (this.props.onFinished) {
                this.props.onFinished(this);
            }
        });
    }

    /**
     * Go to the previous step.
     * @param e
     */
    onPrevStep(e) {
        this.changeStep(this.state.step - 1);
    }

    /**
     * Go to the next step.
     * @param e
     */
    onNextStep(e) {
        this.changeStep(this.state.step + 1);
    }

    onSetStep(step) {
        this.changeStep(step);
    }

    /**
     * Renders the indicator at the bottom that displays where in the process the user is.
     * @returns {Array}
     */
    renderIndicator() {

        const commonClasses = "fa fa-circle fa-2x ";

        var i,
            result = [],
            count = this.getStepCount(),
            classNames;

        for (i = 1; i <= count; i++) {

            classNames = commonClasses + (i > this.state.step ?
                    "wizard-indicator-incomplete" :
                    "wizard-indicator-complete");

            result.push(<i onClick={this.onSetStep.bind(this, i)} data-step={i} className={classNames}></i>);
        }

        return result;
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
        if (this.state.isFinished) {
            return <div></div>;
        }
        return (
            <div data-step="1" className="wizard">
                {this.renderChildren()}
                <hr />
                <div className="wizard-finish-message">
                {this.state.step === this.getStepCount() && this.props.renderFinishMessage ? this.props.renderFinishMessage() : ''}
                </div>
                <div className="wizard-controls">
                    <div className="wizard-buttons">
                        <button onClick={this.onPrevStep.bind(this)} style={{ display: (this.state.step === 1 ? 'none' : 'inline-block') }} className="btn btn-lg btn-success">Previous</button>
                        <button onClick={this.onNextStep.bind(this)} className={'btn btn-lg ' + (this.state.step === this.getStepCount() ? 'btn-info' : 'btn-success')}>
                            {(this.state.step === this.getStepCount() ? 'Finish' : 'Next')}
                        </button>
                    </div>
                    <div className="wizard-message">Step {this.state.step} of {this.getStepCount()} - {this.state.message}</div>
                    <div className="wizard-indicator">{this.renderIndicator()}</div>
                </div>
            </div>
        );
    }
}