//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

/**
 * Renders whether a textbox meets the specified RegEx validation requirement.
 */
export default
class PasswordRequirement extends React.Component {

  /**
   * Default constructor.
   */
  constructor() {
    super();
  }

  /**
   * Renders the icon shown to the user.
   * @returns {XML}
   */
  renderIcon() {
    if(this.props.simple === "true"){
      if (this.props.value.match(new RegExp(this.props.exp))) {
        return <span className="dot dot-green"></span>
      } else {
        return <span className="dot dot-red"></span>
      }
    } else {
      if (this.props.value.match(new RegExp(this.props.exp))) {
        return (<i className="fa fa-check-circle fa-lg requirement-met"></i>);
      } else {
        return (<i className="fa fa-times-circle fa-lg requirement-not-met"></i>);
      }
    }
  }

  /**
   * Render the component.
   * @returns {XML}
   */
  render() {

    return (<div className="password-requirement">
  <span className="icon">
    {this.renderIcon()}
  </span>
           &nbsp;{this.props.message}
    </div>);
  }
}
