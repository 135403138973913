//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import Wizard from './Wizard.jsx';
import WizardStep from './WizardStep.jsx';
import PasswordRequirement from './PasswordRequirement.jsx';
import VerifyPhoneNumber from './VerifyPhoneNumber.jsx';
import LoginDisplay from './LoginDisplay.jsx';
import Radio from './Radio.jsx';
import Checkbox from './Checkbox.jsx';
import Address from './Address.jsx';
import ErrorModal from './ErrorModal.jsx';
import PhoneNumber from './PhoneNumber.jsx';

export default { Wizard, WizardStep, PasswordRequirement, VerifyPhoneNumber, LoginDisplay, Radio, Checkbox, ErrorModal, Address, PhoneNumber };