//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';
import PhoneNumber from './PhoneNumber.jsx';
import {requestPhoneVerification, getConsumer, updateConsumer, verifyPhone, resend} from '../../services/consumer';
import {getToken} from '../../services/session';
import {handleError} from '../../utils';

export default
class VerifyPhoneNumber extends ViewBase {

    constructor(props) {
        super();

        this.state = {
            message: 'loading...',
            loaded: false,
            inProgress: false,
            isVerified: false,
            showMessage: false,
            isResend: false
        };

        if (getToken()) {

            getConsumer().then(model => {

                this.setState({
                    loaded: true,
                    codeSent: false,
                    mobileNumber: model.mobileNumber
                });

                if (props.autoLoad) {
                    requestPhoneVerification()
                        .then(() => this.setState({codeSent: true}))
                        .catch(handleError);

                }
            });
        }
        else {
            this.state.message = 'You are not currently logged in.';
        }

        this.constraints = {
            mobileNumber: {
                presence: true,
                length: {is: 10},
                numericality: {
                    onlyInteger: true
                }
            }
        };
    }

    onVerify() {
        this.setState({inProgress: true});
        verifyPhone(React.findDOMNode(this.refs.code).value)
            .then(result => {
                this.setState({inProgress: false, isResend: false, isVerified: result.isVerified, showMessage: true});
                if (this.props.onVerify) {
                    this.props.onVerify();
                }
                if (result.isVerified) {
                    this.onProceed();
                }
            });
    }

    tryAgain() {
        this.setState({
            showMessage: false
        })
    }

    onProceed() {
        if (this.props.onProceed) {
            this.props.onProceed();
        }
    }

    resendActivationNumber() {
        this.setState({inProgress: true});
        resend().then(result => {
            this.setState({inProgress: false, isResend: true});
        });
    }

    requestCode() {

        updateConsumer({
            mobileNumber: this.state.mobileNumber
        })
            .then(requestPhoneVerification)
            .then(() => this.setState({codeSent: true}))
            .catch(handleError);
    }

    renderVerification() {
        if (this.state.codeSent) {
            return (<div>
                <div className="left" style={{
                    width: '100%',
                    paddingBottom: '10px',
                    display: !this.state.inProgress && this.state.isResend ? 'block' : 'none'
                }}>
                    <i className="fa fa-check-circle fa-lg" style={{color: 'green'}}></i>&nbsp;The new code was sent.
                </div>
                <div className="left" style={{display: !this.state.showMessage ? 'block' : 'none'}}>
                    <input disabled={this.state.inProgress} autoFocus={true} type="text"
                           className="form-control submit-direct"
                           style={{width: '100px', marginRight: '10px'}} ref='code'/>
                </div>
                <div className="left" style={{
                    width: '100%',
                    display: !this.state.inProgress && this.state.isVerified && this.state.showMessage ? 'block' : 'none'
                }}>
                    <i className="fa fa-check-circle fa-lg" style={{color: 'green'}}></i>&nbsp;Your phone is verified.
                </div>
                <div className="left" style={{
                    width: '100%',
                    display: !this.state.inProgress && !this.state.isVerified && this.state.showMessage ? 'block' : 'none'
                }}>
                    <i className="fa fa-times-circle fa-lg" style={{color: 'red'}}></i>&nbsp;Your phone was <b>not</b>
                    verified.
                    <button className="btn btn-warning btn-lg" onClick={this.tryAgain.bind(this)}>Try Again</button>
                </div>
                <div className="left">
                    <i className="fa fa-spin fa-spinner"
                       style={{fontSize: '24pt', display: (this.state.inProgress ? 'inline' : 'none')}}></i>
                    <button className="btn btn-success"
                            style={{display: !this.state.inProgress && !this.state.showMessage ? 'unset' : 'none'}}
                            onClick={this.onVerify.bind(this)}>Verify
                    </button>
                    <button className="btn btn-info" style={{
                        marginLeft: '10px',
                        display: !this.state.inProgress && !this.state.showMessage ? 'unset' : 'none'
                    }}
                            onClick={this.resendActivationNumber.bind(this)}>Resend Code
                    </button>
                </div>
            </div>);
        }
        return '';
    }

    renderPhone() {
        if (this.props.hidePhone || (this.state.codeSent && !this.state.inProgress && !this.state.showMessage)) {
            return <div className="col-md-4"></div>;
        }
        else {
            return <div className="col-md-4 col-md-offset-2">
                Enter your Mobile Number<br />
                <PhoneNumber target={this} name="mobileNumber"/>
                {this.renderValidation('mobileNumber')}
            </div>
        }
    }

    componentDidMount() {
        var node = React.findDOMNode(this);
        node.addEventListener('keydown', e => {
            if (e.keyCode === 13 && e.target.classList.contains('submit-direct')) {
                this.onVerify();
            }
        });
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        {this.renderPhone()}
                        <div className="col-md-4" style={{marginTop: '19px'}}>
                            <button onClick={this.requestCode.bind(this)}
                                    style={{display: this.state.codeSent ? 'none' : 'block'}}
                                    className="btn btn-info">
                                <i className="fa fa-phone"></i>&nbsp;Request Verification Code
                            </button>
                            {this.renderVerification()}
                        </div>
                    </div>
                </div>
            </div>);
    }
}