//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ErrorModal from '../views/components/ErrorModal.jsx';

export function handleError (e) {

    // 1) log the error to the server (if possible)...
    console.error(e);

    // 2) show the user a friendly message.
    var element = document.createElement('DIV');
    element.id = 'errorModalContainer';
    document.body.appendChild(element);

    React.render(React.createElement(ErrorModal, null), element);
}

export function handleCustomError (message) {
    return function (e) {
        // 1) log the error to the server (if possible)...
        console.error(e);

        // 2) show the user a friendly message.
        var element = document.createElement('DIV');
        element.id = 'errorModalContainer';
        document.body.appendChild(element);

        React.render(React.createElement(ErrorModal, { message }), element);
    }
}