//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

import ViewBase from '../ViewBase.jsx';
import pageEvents from '../../events/page';
import { getHomePage } from '../../services/user';
import { getSession, setSession, initiateSession } from '../../services/session';
import { Button, Modal } from 'react-bootstrap';
import { md5 } from '../../utils';
import Checkbox from './Checkbox.jsx';

export default
class LoginDisplay extends ViewBase {


    handleSignIn() {
        var state = (getSession() || {}), result = {};

        for (var key in state) {
            result[key] = state[key];
        }
        result.isLoggedIn = !!getSession();
        this.setState(result, () => {

            var node = React.findDOMNode(this);
            node.addEventListener('keydown', e => {
                if (e.keyCode === 13 && e.target.classList.contains('submit-direct')) {
                    this.doSignInDirect();
                }
            });
        });
    }

    /**
     * Default constructor.
     */
    constructor() {
        super();

        this.state = {
            showSignInDialog: false,
            signInEmail: null,
            signInPassword: null
        };

        pageEvents.on('user:signIn', this.handleSignIn.bind(this));
        pageEvents.on('user:signOut', this.handleSignIn.bind(this));
        pageEvents.on('user:requestSignIn', this.onSignIn.bind(this));

        this.constraints = {
            signInEmail: {

            },
            signInPassword: {

            }
        }
    }

    buildGravitarUrl(size) {

        //try {
        //    var email = this.state.emailAddress;
        //    var imgSize = size || 80;
        //    return 'http://www.gravatar.com/avatar/' + md5(email) + '.jpg?s=' + imgSize;
        //}
        //catch (e) {
            return '/content/images/user.png';
        //}
    }

    onBtnSignIn() {
        this.onSignIn();
    }

    onSignIn() {
        this.setState({
            showSignInDialog: true
        }, function () {
            var node = React.findDOMNode(this.refs.modalBody);
            if (node) {

                node.addEventListener('keydown', e => {
                    if (e.keyCode === 13 && e.target.classList.contains('submit-modal')) {
                        this.doSignIn();
                    }
                });
            }
        });
    }

    redirectUser() {
        window.location = getHomePage();
    }

    doSignInDirect() {
        pageEvents.once('user:signIn', this.redirectUser);
        initiateSession($('#fieldEmail').val(), $('#fieldPassword').val())
            .then(setSession)
            .catch(() => this.setState({ signInDialogMessage: 'Invalid email address or password!', messageType: 'warning' }));
    }

    doSignIn() {
        pageEvents.once('user:signIn', this.redirectUser);
        initiateSession(this.state.signInEmail, this.state.signInPassword)
            .then(setSession)
            .catch(() => this.setState({ signInDialogMessage: 'Invalid email address or password!' }));
    }

    getMessage() {
        if (this.state.messageType) {
            return (<i className={`fa fa-${this.state.messageType} fa-2x`} title={this.state.signInDialogMessage}></i>);
        }
        else {
            return (<span>{this.state.signInDialogMessage}</span>);
        }
    }

    onSignOut() {
        setSession(null, true);
        setSession(null, false);
        window.location = '/';
    }

    closeSignIn() {
        this.setState({
            showSignInDialog: false
        })
    }

    componentDidMount() {

        super.componentDidMount();

        this.handleSignIn();
    }

    renderSignInDialog() {
        return (<div>
            <Modal show={this.state.showSignInDialog}
                enforceFocus={true}
                autoFocus={true}
                backdrop={false}
                animation={true}
                container={document.getElementById('body')}
                onHide={this.closeSignIn.bind(this)}>

                <Modal.Header closeButton>
                    <Modal.Title><i className="fa fa-user" />&nbsp;Sign In</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div ref="modalBody">
                        <b>{this.state.signInDialogMessage}</b>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="fieldSignInEmail">Email Address</label>
                                    <input id="fieldSignInEmail" type="text" className="form-control" autoFocus={true} name="signInEmail" onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="fieldSignInPassword">Password</label>
                                    <input id="fieldSignInPassword" type="password" className="form-control submit-modal" name="signInPassword" onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Checkbox target={this} name="signInRememberMe" value="Remember me on this device." />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='primary' onClick={this.doSignIn.bind(this)}>Sign In</Button>
                    <Button onClick={this.closeSignIn.bind(this)}>Close</Button>
                </Modal.Footer>
            </Modal>
            </div>);
    }

  renderNewSignIn() {
    return (<div className="login-section">
              <div className="login-title">
                <h3>
                  <span className="fa fa-user"></span>
                  <em>Login</em>
                </h3>
                <a href="#" className="fa fa-close cancel"></a>
              </div>
              <div className="login-body">
                <input id="fieldEmail" type="text" className="field" placeholder="Email Address" /><br />
                <input id="fieldPassword" type="password" className="field submit-direct" placeholder="Password" /><br />
                <div className="login-bot clearfix">
                  <div className="btn-wrap">
                    <input type="submit" value="Sign in" className="btn btn-secondary btn-signin" onClick={this.doSignInDirect.bind(this)} />
                  </div>
                  <div style={{float: 'right', padding: '10px', color: 'tomato'}}>{this.getMessage()}</div>
                  <div className="remember-info">
                    <p>
                      <input type="checkbox" name="remember" id="remember" />
                      <label for="remember">Remember Me</label>
                    </p>
                    <a href="/user/forgot-password"><strong>Forget Password?</strong></a>
                  </div>
                </div>
              </div>
    </div>);
  }


   //<h6><a href="/register/consumer">Register</a></h6>

    renderSignedOut() {
        return (<div>
                    <div className="phone-hide">
                        <div style={{float: 'right', margin: '5px'}}>
                            <a href="#" className="btn btn-success submit-direct" onClick={this.doSignInDirect.bind(this)}>Sign In</a><br />
                        </div>
                        <div style={{float: 'right', margin: '5px'}}>
                            <input id="fieldPassword" tabIndex="2" type="password" className="form-control inline submit-direct" placeholder="Password" /><br />
                            <h6><a href="/user/forgot-password">Forgot Password?</a></h6>
                        </div>
                        <div style={{float: 'right', margin: '5px'}}>
                            <input id="fieldEmail" tabIndex="1" autoFocus={true} type="text" className="form-control inline" placeholder="Email Address" /><br />
                            <Checkbox target={this} name="signInRememberMe" value="Remember me" className="remember-me-checkbox" />
                        </div>
                        <div style={{float: 'right', padding: '10px', color: 'tomato'}}>{this.getMessage()}</div>
                    </div>
                    <div className="phone-show">
                        <a href="#" onClick={this.onBtnSignIn.bind(this)} className="btn btn-success">Login</a>
                        {this.renderSignInDialog()}
                    </div>
            </div>);
    }
//<img src={this.buildGravitarUrl(48)} alt={this.state.name} style={{ width: '24px', borderRadius: '5px', border: '1px solid #aaaaaa' }} />

    renderSignedIn() {
        return (<div>
            <div className="phone-hide">
                <i className="fa fa-user"></i>
                &nbsp;<a href={getHomePage()}><b>{this.state.name}</b></a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="/user/change-email">Account</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="#" onClick={this.onSignOut.bind(this)}>Sign Out</a>
            </div>
            <div className="phone-show" style={{width: '80px'}}>
                <a className="btn btn-success" href={getHomePage()} style={{marginBottom: '5px'}}><b>Home</b></a>
                <a className="btn btn-primary" href="#" onClick={this.onSignOut.bind(this)}>Logout</a>
            </div>
        </div>);
    }

        /**
     * Render the component.
     * @returns {XML}
     */
    render() {

        return (<div id="loginComponentParent">
            {this.props.refresh === "true" ? this.renderNewSignIn() : (this.state.isLoggedIn ? this.renderSignedIn() : this.renderSignedOut())}
        </div>);
    }
}
