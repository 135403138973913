//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';

/**
 * Represents a step within a wizard.
 */
export default
class WizardStep extends ViewBase {

    /**
     * Default constructor.
     */
    constructor() {
        super();
        this.state = {
            shown: false,
            children: []
        };
    }

    componentDidUpdate() {
        var node = React.findDOMNode(this).querySelector('.focus-first');
        if (node && node.focus) {
            node.focus();
        }
    }

    /**
     * Shows the current step.
     */
    show() {
        this.setState({ shown: true });
    }

    /**
     * Hides the current step.
     */
    hide() {
        this.setState({ shown: false });
    }

    /**
     * Gets the message to display for the current step.
     * @returns {*}
     */
    getMessage() {
        return this.props.message;
    }

    /**
     * Called after component loads.
     */
    componentDidMount() {

        super.componentDidMount();
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
        return (
            <div className="wizard-section" style={{ display: (this.state.shown ? 'block' : 'none') }}>
                {this.renderChildren()}
            </div>
        );
    }
}