//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

export default
class Radio extends React.Component {

    /**
     * Default constructor.
     */
    constructor() {
        super();
    }

    componentDidMount() {
    }

    renderLabel() {
        return (<label htmlFor={this.props.name + '_' + this.props.value}>
            {this.props.hideLabel ? '' : this.props.value}
        </label>);
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
       return (<div className={"radio " + this.props.className}>
           <input type="radio" value={this.props.value} id={this.props.name + '_' + this.props.value} name={this.props.name} checked={this.props.target.state[this.props.name] === this.props.value} onChange={this.props.target.handleChange.bind(this.props.target)} disabled={this.props.disabled} />
           {this.renderLabel()}
       </div>);
    }
}
