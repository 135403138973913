//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import md5 from './md5';
import { handleError, handleCustomError } from './handleError';
import { getToken } from '../services/session';
import config from '../config';

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getDownloadLink(route, format, parameters) {
    var token = getToken();
    var params = '';
    for (var key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            params += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]);
        }
    }

    return `${config.api.url}${route}/?type=download&auth=${token}&format=${format}${params}`;
}

function formatPhoneNumber (val) {
    var phone = val.toString();
    if (phone.length === 10) {
        return '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + ' - ' + phone.substr(6, 4);
    }
    return '(Not a Phone Number)';
}

function displayTimeSpan(val) {

    var sec_num = parseInt(val, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return '(None)';
    }

    return hours+':'+minutes+':'+seconds;

}

export { md5, handleError, handleCustomError, getParameterByName, getDownloadLink, formatPhoneNumber, displayTimeSpan };