//******************************************************************************
//*                                                                            *
//* (C) 2016 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************
//* NOTICE: THIS IS AUTO-GENERATED CODE, ALL CHANGES HERE WILL BE LOST.        *
//******************************************************************************

export default {"api":{"url":"https://www.api.optincall.com"}}