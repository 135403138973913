//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

export default
class PhoneNumber extends React.Component {

    /**
     * Default constructor.
     */
    constructor() {
        super();
        this.state = {};
    }

    handleChange(context, num) {
        return function () {
            var state = {}, self = this;

            state[this.props.name] = this.refs.tel1.getDOMNode().value +
                this.refs.tel2.getDOMNode().value +
                this.refs.tel3.getDOMNode().value;

            // if the current text box is focused
            var node = this.refs['tel' + num].getDOMNode();
            // if (node === document.activeElement) {
            //     if (node.value.length === 0 && num > 1) {
            //         this.refs['tel' + (num - 1)].getDOMNode().focus();
            //     }
            //     if (node.value.length === node.maxLength && num < 3) {
            //         this.refs['tel' + (num + 1)].getDOMNode().focus();
            //     }
            // }

            this.props.target.setState(state, function () {
                if (self.props.onChange) {
                    self.props.onChange.call(self);
                }
            });
        }.bind(context);
    }

    getValue (start, len) {
        var value = ((this.props.target.state[this.props.name] || '') + '           ').substring(0, 10);
        return value.substring(start, len).trim();
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
      var phoneClass;
      var phoneBaseSize;
      if(this.props.simple == "true"){
        phoneClass = "txt-field";
        phoneBaseSize = 110;
      } else {
        phoneClass = "form-control inline";
        phoneBaseSize = 50;
      }
        return <div className={this.props.className}>
            <input ref="tel1" onBlur={this.props.onBlur} tabIndex={this.props.tabIndex} type="tel" placeholder="555" maxLength="3" style={{width: '' + phoneBaseSize + 'px'}} className={phoneClass} size="1" name={this.props.name + "1"} onChange={this.handleChange(this, 1)} value={this.getValue(0, 3)}/>&nbsp;
            <input ref="tel2" onBlur={this.props.onBlur} tabIndex={this.props.tabIndex} type="tel" placeholder="555" maxLength="3" style={{width: '' + phoneBaseSize + 'px'}} className={phoneClass} size="1" name={this.props.name + "2"} onChange={this.handleChange(this, 2)} value={this.getValue(3, 6)}/>&nbsp;
            <input ref="tel3" onBlur={this.props.onBlur} tabIndex={this.props.tabIndex} type="tel" placeholder="5555" maxLength="4" style={{width: '' + (phoneBaseSize + (phoneBaseSize * 0.2)) + 'px'}} className={phoneClass} size="1" name={this.props.name + "3"} onChange={this.handleChange(this, 3)} value={this.getValue(6, 10)}/>
          </div>;
    }
}
