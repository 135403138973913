//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

export function translateTimezone(tz, isAZ) {
    switch (tz) {
        case 'Central':
            return 'America/Chicago';
        case 'Mountain':
            if (isAZ) {
                return 'America/Phoenix';
            }
            return 'America/Denver';
        case 'Pacific':
            return 'America/Los_Angeles';
        default:
            return 'America/New_York';
    }
}

export function untranslateTimezone(tz) {
    switch (tz) {
        case 'America/Chicago':
            return {
                timezone: 'Central',
                useArizonaTime: false
            };
        case 'America/Phoenix':
            return {
                timezone: 'Mountain',
                useArizonaTime: true
            };
        case 'America/Denver':
            return {
                timezone: 'Mountain',
                useArizonaTime: false
            };
        case 'America/Los_Angeles':
            return {
                timezone: 'Pacific',
                useArizonaTime: false
            };
        default:
            return {
                timezone: 'Eastern',
                useArizonaTime: false
            };
    }
}


export function cleanField(field) {
    if (field === 'Select an option...' || field === 'Choose...') {
        return undefined;
    }
    return field;
}

export function clean(obj, whitelist) {
    var result = {};
    for (var key in obj) {
        if (obj.hasOwnProperty(key) && (!whitelist || whitelist.indexOf(key) > -1)) {
            result[key] = cleanField(obj[key]);
        }
    }
    return result;
}