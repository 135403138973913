//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

export default
class Address extends React.Component {

    /**
     * Default constructor.
     */
    constructor(props) {
        super();
        props.target.refreshAddress = this.refreshAddress.bind(this);
    }

    componentDidMount() {
    }


    refreshAddress(org) {
        console.log('refreshing...');
        this.setState(org);
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {

        console.log('render address: ' + JSON.stringify(this.props.target.state));
        var name = function(n) {
            return `${n}${this.props.name ? "_" + this.props.name : ''}`;
        }.bind(this);

        return (<div className="row">
            <div className="col-md-3">
                <label htmlFor="fieldAddress1">Address Line 1<span className={this.props.requireAddress ? "required" : "optional"}></span></label>
                <input tabIndex={this.props.tabIndex + 0} id={'field' + name('address1')} type="text" className="form-control" name={name('address1')} value={this.props.target.state[name('address1')]} onChange={this.props.target.handleChange.bind(this.props.target)} />
                {this.props.target.renderValidation(name('address1'))}
            </div>
            <div className="col-md-3">
                <label htmlFor="fieldAddress2">Address Line 2<span className="optional"></span></label>
                <input tabIndex={this.props.tabIndex + 1} id={'field' + name('address2')} type="text" className="form-control" name={name('address2')} value={this.props.target.state[name('address2')]} onChange={this.props.target.handleChange.bind(this.props.target)} />
            </div>
            <div className="col-md-2">
                <label htmlFor="fieldCity">City<span className={this.props.requireAddress ? "required" : "optional"}></span></label>
                <input tabIndex={this.props.tabIndex + 2} id={'field' + name('city')} type="text" className="form-control" name={name('city')} value={this.props.target.state[name('city')]} onChange={this.props.target.handleChange.bind(this.props.target)} />
                {this.props.target.renderValidation(name('city'))}
            </div>
            <div className="col-md-2">
                <label htmlFor="fieldState">State<span className={this.props.requireAddress ? "required" : "optional"}></span></label>
                <select tabIndex={this.props.tabIndex + 3} className="form-control" id={'field' + name('state')} name={name('state')} value={this.props.target.state[name('state')]} onChange={this.props.target.handleChange.bind(this.props.target)}>
                    <option>Select an option...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>
                {this.props.target.renderValidation(name('state'))}
            </div>
            <div className="col-md-2">
                <label htmlFor="fieldZip">Zip Code<span className="required"></span></label>
                <input tabIndex={this.props.tabIndex + 4} id={'field' + name('zipCode')} type="number" className="form-control no-spin" name={name('zipCode')} value={this.props.target.state[name('zipCode')]} onChange={this.props.target.handleChange.bind(this.props.target)} maxLength="5" />
                {this.props.target.renderValidation(name('zipCode'))}
            </div>
        </div>);
    }
}