//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

export default
class Checkbox extends React.Component {

    /**
     * Default constructor.
     */
    constructor() {
        super();
    }

    componentDidMount() {
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
        return (<div className={`checkbox ${this.props.className}`} style={this.props.style || {}}>
            <input type="checkbox" id={this.props.name} name={this.props.name} checked={this.props.target.state[this.props.name]} onChange={this.props.target.handleChange.bind(this.props.target)} className={this.props.className} />
            <label htmlFor={this.props.name}>
                {this.props.value}
            </label>
        </div>);
    }
}
