//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';

import { Button, Modal } from 'react-bootstrap';

export default
class ErrorModal extends React.Component {

    /**
     * Default constructor.
     */
    constructor() {
        super();

        this.state = {
            showDialog: true
        };
    }

    closeDialog() {
        window.location = '/'; // go back to the homepage if something bad happened.
    }

    renderMessage() {
        if (this.props.message) {
            return this.props.message;
        }
        else {
            return "An error occurred while attempting to complete your request. The error has been recorded and will be reviewed by our engineering team. Have a nice day.";
        }
    }

    /**
     * Render the component.
     * @returns {XML}
     */
    render() {
        return (<div>
            <Modal show={this.state.showDialog}
                   enforceFocus={true}
                   autoFocus={true}
                   backdrop={"static"}
                   animation={true}
                   container={document.getElementById('body')}>

                <Modal.Header>
                    <Modal.Title><i className="fa fa-warning" />&nbsp;Oops...</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div ref="modalBody">
                        {this.renderMessage()}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='primary' onClick={this.closeDialog.bind(this)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}